* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
}

body {
  background: $light-gray;
  color: $dark-gray;
  font-family: $base-font;
  font-weight: $regular-weight;
  font-size: $base-size;
}

a {
  color: $dark-gray;
  -webkit-tap-highlight-color: transparent;
}

button {
  -webkit-tap-highlight-color: transparent;
}

input, textarea, select {
  font-family: $base-font;
}

.page-title {
  color: black;
  font-weight: $regular-weight;
  font-size: 18px;
  margin: 0 0 $small-gutter 0;
  @include m('bold') {
    font-weight: $bold-weight;
  }
}

.center {
  text-align: center;
}

.top-actions {
  margin: 0 -5px $small-gutter -5px;
  .link {
    margin: 5px;
  }
}

.back-to-list {
  color: $dark-gray;
  text-decoration: none;
  font-size: 13px;
  display: inline-block;
  margin: 0 0 $small-gutter 0;
}

.mb-20 {
  margin-bottom: 20px;
}
