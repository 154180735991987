.dashboard-items-charts {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: $small-gutter;
  &--two-columns {
    @include tabletUp {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.dashboard-items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: $small-gutter;
  @include tabletUp {
    display: flex;
    // justify-content: space-between;
  }
  .module-count {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.dashboard-chart {
  @include e('main-percentage') {
    text-align: center;
    margin-bottom: 20px;
    @include m('up') {
    }
    @include m('down') {
    }
    @include e('value') {
      font-size: 48px;
      font-weight: 600;
    }
    @include e('label') {
      font-weight: 400;
    }
  }
  @include e('items') {
    display: flex;
    justify-content: center;
    @include e('item') {
      flex-grow: 1;
      text-align: center;
      margin: 0 5px;
      @include e('value') {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 10px;
        @include e('label') {
          font-weight: 600;
          padding: 3px;
          font-size: 18px;
          // background: #555;
          // color: white;
          // margin-bottom: 1px;
        }
        @include e('bar') {
          background: #333;
        }
      }
      @include e('date') {
        background: #ddd;
        padding: 6px 3px;
        line-height: 1;
        text-transform: lowercase;
        @include e('day') {
          font-size: 24px;
          font-weight: 600;
        }
        @include e('weekday') {
          font-size: 16px;
        }
        @include e('month') {
          font-size: 12px;
        }
        @include e('year') {
          font-size: 12px;
        }
      }
    }
  }
  @include e('label') {
    margin-top: 10px;
    text-align: center;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before, &:after {
      content: "";
      display: inline-block;
      margin: 0 10px 0;
      width: 20px;
      border-top: 3px solid;
    }
  }
}
